import React, { Component } from "react";
import "./table.css";
import Hexapi from '../../utility/HexAPI/Hexapi';
import { Spinner, Modal } from 'react-bootstrap'
import "./styles.css";
import Example from './printtrigger'
import { QrReader } from 'react-qr-reader'
import TimeFormatter from '../../utility/newTimeformatter/TimeFormatter'
import Datatable from "./table/Datatable";

class homepg extends Component {
    constructor() {
        super()
        this.state = {
            userid: sessionStorage.getItem('userid'),
            header: [],
            tableData: [],
            livevisitor: [],
            test2visitor: [],
            testvisitor: [],
            remarks: "",
            isApiComplete: false,
            isDataAvailable: false,
            isModalOpen: false,
            isnewModalOpen: false,
            recid: "",
            date: "",
            data: "",
            callBack: false,
            visitorinfo: "",
            visitorid: "",
            showvisitor: "",
            headerdata: [],
            showimage: false,
            profileimage: ''
        }
        this.vistortableclick = this.vistortableclick.bind(this);
        this.vistorexit = this.vistorexit.bind(this);
        this.closemodal = this.closemodal.bind(this);
        this.fetchTableData = this.fetchTableData.bind(this);
        
    }
    closemodal() {
        this.setState({ isModalOpen: false })
    }

    newclosemodal() {
        this.setState({ isnewModalOpen: false })
    }

    qrhandle() {
        let obj = {
            'query': `[dbo].[VRApp_Web_Proc_Organisation_GetDetails]`,
            'orgdbname': `${sessionStorage.getItem('orgdbname')}`,
            'queryArr': []
        }
        Hexapi(obj).then(resp => {
            this.setState({ qrconfig: resp[""][0]['qrconfig'] })
            console.log(resp[""][0]['qrconfig'])
            console.log(this.state.qrconfig)
        }).catch(err => { console.error(err) })
    }
    fetchTableData() {
        this.setState({ isApiComplete: false, isDataAvailable: false })
        let obj = {
            'query': `[dbo].[VRApp_Web_Proc_LiveVisitor_GetListV2] @UserID='{0}'`,
            'queryArr': [
                `${this.state.userid}`,
            ]
        }
        console.log(obj,"resphomepg")
        Hexapi(obj).then(resp => {
            console.log(resp,"respjhgfcfhsuj----------------")
            // console.log(Array.isArray(resp['']))
            // console.log(resp[''],"resphomepg")
            this.setState({ headerdata: resp[''] })
            if (resp[''].length > 0 && resp[''] != null) {
                var keys = Object.keys(resp[''][0]);
                console.log(keys)
                let head = [].push(keys);
                console.log(head)
                this.setState({ headerdata: resp[''] })
                console.log(this.state.headerdata)
                this.setState({ tableData: resp[''], header: Object.keys(resp[''][0]), isDataAvailable: true })
            } else {
                this.setState({ isDataAvailable: false })
            }
        }).then(resp => {
            this.setState({ isApiComplete: true })
        })
        let totaldata = this.state.tableData;
        console.log(totaldata)
    }
    vistortableclick(s) {
        this.setState({ isModalOpen: true })
        console.log(s)
        this.setState({ visitorinfo: s })
        this.setState({ visitorid: s.recid })
    }
    vistorexit() {
        console.log(this.state.qrconfig)
        if (this.state.qrconfig !== "0") {
            this.setState({ isnewModalOpen: true })
        } 
    }
    setDate(date) {
        if (date != undefined) {
            console.log(date)
            var transformdate = date.split(".")[0];
            console.log(transformdate)
            //   console.log(date);
            var exitdate = TimeFormatter(transformdate);
            console.log(exitdate);
            var visitorinfo = this.state.visitorinfo
            var newReverseDate =
                exitdate.ShiftTime +
                "," +
                exitdate.userSplitedDate[2] +
                " " +
                exitdate.ShiftMonth +
                "`" +
                exitdate.userSplitedDate[0].toString().slice(-2);
            console.log(newReverseDate);

            return newReverseDate;
        }
    }

    printpdf() {
        this.setState({ callBack: true })

    }

    componentDidMount() {
        this.fetchTableData()
        this.qrhandle()
    }

    imagefunction = (data) => {
        console.log(data)
        this.setState({ profileimage: data })
        setTimeout(() => {
            console.log(this.state.profileimage)
            this.setState({ showimage: true });

        }, 500);
    }

    render() {
        return (
            <>
                <Modal
                    id="modalcss"
                    show={this.state.isnewModalOpen}
                    onHide={() => this.newclosemodal()}
                    style={{ backgroundColor: "rgba(0,0,0,0.9)" }}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title></Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <QrReader
                            onResult={(result, error) => {
                                if (!!result) {
                                    this.setState({ data: result?.text })

                                    this.setState({ openscannermodal: false })
                                    console.log(this.state.data)

                                    setTimeout(() => {
                                        <a href={`${sessionStorage.getItem("url")}`}
                                            target="_blank"
                                            style={{ minHeight: "48px" }}>
                                        </a>
                                        this.getqrdata()
                                    }, 100);
                                }

                                if (!!error) {
                                    //   console.info(error);
                                }
                            }}
                            style={{ width: '10%' }}
                            constraints={{
                                facingMode: 'environment'
                            }}
                        />
                    </Modal.Body>
                </Modal>
                <Modal id="ModalisOpen" class="fade" style={{ backgroundColor: 'rgba(0,0,0,0.6)', display: "50px" }} centered show={this.state.isModalOpen} onHide={() => this.setState({ isModalOpen: false })}>

                    <Modal.Body>
                        <div className="Container" style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} >
                            <h2 style={{ fontWeight: "500", fontSize: "20px", color: "black" }} >Card View</h2>

                            <div >                                
                                <button type="button" onClick={(e) => this.closemodal(e)} style={{ marginLeft: "0px", top: "15px", border: "none",background: "transparent" }} class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times " aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <Example value={this.state.visitorinfo} callback={this.imagefunction}
                        />
                    </Modal.Body>

                </Modal>

                <Modal centered class="fade" style={{ backgroundColor: 'rgba(0,0,0,0.6)', }} show={this.state.showimage} onHide={() => this.setState({ showimage: false })}>

                    <Modal.Body>
                            <div >
                                <button type="button" onClick={(e) => this.setState({ showimage: false })} style={{ border: "none" }} class="close" data-dismiss="modal" aria-hidden="true"><i class="fa fa-times " aria-hidden="true"></i></button>
                            </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>

                            <div className="Container" style={{ marginTop:'5%', height: '400px', width: '400px', backgroundImage: `url(${this.state.profileimage})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", }} >
                            </div>

                        </div>
                    </Modal.Body>

                </Modal>

                <div className="container-fulid" style={{ padding: "20px", display: "fixed" }}>
                    <div className="row" >
                        <div className="col-md-12" >
                            {
                                this.state.isApiComplete ?
                                    this.state.isDataAvailable ?
                                        <div>
                                            <Datatable
                                                data={this.state.headerdata}
                                                headStyle={{ textTransform: "Capitalize",backgroundColor:'white', zIndex:'2' }}
                                                value={this.state.visitorinfo}
                                                heading="Live Visitor Table"
                                                isNavbar={true}
                                                pagination={{
                                                    rowsPerPage: 10,
                                                    rowsPerPageOptions: [10, 50, 100, { label: 'All', value: -1 }]
                                                }}
                                                onRowClick={(e) => this.vistortableclick(e)}
                                                refreshtable={()=>this.fetchTableData()}
                                                callback={this.imagefunction}
                                                
                                            />
                                        </div>
                                        :
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "50vh",
                                            }}
                                        >
                                            <h3 style={{ fontFamily: "sans-serif", color: "rgb(153, 135, 170)" }}>No Records!</h3>
                                        </div>


                                    :
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            width: "100%",
                                            height: "50vh",
                                            alignItems: "center"

                                        }}
                                    >
                                        <Spinner animation="border" variant="dark" />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

            </>
        );
    }

}

export default homepg;